import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  computed: {
    modalUrl: function modalUrl() {
      var url = this.$store.getters['modals/profileRedirectModal'].url;
      if (url.includes('bonus')) return 'bonus';
      if (url.includes('withdraw')) return 'withdraw';
      return 'deposit';
    }
  },
  methods: {
    goToProfile: function goToProfile() {
      if (window !== window.top) {
        window.top.postMessage({
          site: {
            redirect: 'profile'
          }
        }, '*');
        window.top.postMessage({
          cash: {
            close: true
          }
        }, '*');
      }

      var path = this.$store.getters['lang/linkTo']({
        page: 'profile'
      });
      this.$router.push({
        path: path
      });
      this.$store.dispatch('modals/setProfileRedirectModal', false);
    },
    close: function close() {
      if (window !== window.top) window.top.postMessage({
        cash: {
          close: true
        }
      }, '*');
      this.$store.dispatch('modals/setProfileRedirectModal', false);
    }
  }
};